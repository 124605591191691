.treeRoot {
    padding-top: 30px;
    padding-bottom: 30px;
}

.app {
    height: 100%;
}

.dropTarget {
    background-color: lightgray;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.placeholderDivStyle {
    width: 100%;
    height: 64px;
}

.infoItemContainer {
    width: 100%;
    display: flex;
    border-bottom: 2px solid #272727;
    height: 64px;
    align-items: center
}

.dragIcon {
    margin-right: 12px;
}

.imageIconContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.childWrapper {
    display: flex; 
    flex-direction: row;
    align-items: center;
}

.openImageIcon {
    margin-right: 12px;
    rotate: 180deg;
}

.closeImageIcon {
    margin-right: 12px;
}

.conciergeInfoIcon {
    margin-right: 12px;
}

.conciergeRowDivider {
    color: #FFFFFF;
    font-size: 14px;
    font-family: SF Pro Display;
    line-height: 16.71px;
}

.explainContainer {
    width: 100%;
    margin-bottom: 32px;
}

.explainWrapper {
    width: 100%;
    display: flex;
}

.headerContainer {
    width: 100%;
    margin-bottom: 32px;
}

.headerWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.headerTitleContainer {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.createItemBtnContainer {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.createItemButton {
    height: 45px;
    min-width: 145px;
}
