a,
article,
div,
h1,
h2,
h3,
h4,
h5,
h6,
li,
main,
ol,
p,
section,
ul {
  box-sizing: border-box;
  color: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
}