.nodeWrapper {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 64px;
    padding-inline-end: 8px;
    padding-inline-start: 8px;
    border-radius: 4px;
    white-space: nowrap;
    position: relative;
    z-index: 3;
    border-bottom: 2px solid #272727;
    /* pointer-events: none !important;
    user-select: none !important;
    cursor: grab; */
}

.nodeWrapper::after {
    border-bottom: 2px solid #191919;
    content: '';
    position: absolute;
    height: 100%;
    width: 32px;
    bottom: -2px;
}
  
.nodeWrapper:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
  
.nodeWrapper .expandIconWrapper {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transform: rotate(0deg);
}
  
.nodeWrapper .expandIconWrapper.isOpen {
    transform: rotate(180deg);
}
  
.nodeWrapper .expandIconWrapper.isOpen svg path {
    fill: #4f5272;
}
  
.nodeWrapper .labelGridItem {
    padding-inline-start: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
}
  
.pipeY {
    position: absolute;
    left: -7px;
    top: -22px;
}
  
.pipeX {
    position: absolute;
    left: -7px;
    top: 32px;
    height: 2px;
    z-index: -1;
}
  
.wrapper {
    display: block;
}
  
.treeRoot {
    list-style-type: none;
    padding-inline-start: 0px;
    position: relative;
}
  
.treeRoot ul {
    list-style-type: none;
    padding-inline-start: 0px;
    position: relative;
    padding-bottom: 5px;
}
  
.treeRoot > li:after {
    display: none;
}
  
.wrapper .draggingSource {
    opacity: 0.3;
}
  
.wrapper .placeholder {
    position: relative;
}
  
.wrapper > ul > li > .nodeWrapper > div.pipeY {
    display: none;
}
  
.wrapper li:has(> .dropTarget) {
    outline: 3px solid #e8f0fe;
    border-radius: 4px;
}

.conciergeInfoIcon {
    margin-left: 12px;
    margin-right: 12px;
}

.dragIcon {
    margin-left: 12px;
    margin-right: 12px;
}
  