.DateRangePickerInput, .SingleDatePickerInput {
  align-items: center;
  display: flex;
}

.DateRangePickerInput__withBorder, .SingleDatePickerInput__withBorder {
  background: rgba(209, 210, 214, .2);   
  border-color: rgba(209, 210, 214, .2);   
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  height: 40px;
}


.error .DateRangePickerInput__withBorder,
.error .SingleDatePickerInput__withBorder
 {
  border-color: #fa8072;   
}

.DateRangePickerInput__withBorder:focus:not(.error),
.SingleDatePickerInput__withBorder:focus:not(.error) {
  border-color: #1FBA77;
}

.DateInput, .DateInput_input {
  background: transparent;
  color: #242731;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0;
  width: 102px;
}

.SingleDatePickerInput .DateInput,
.SingleDatePickerInput .DateInput_input {
  box-sizing: border-box;
  width: 100%;
}

.DateInput_input {
  padding: 0 15px;
}